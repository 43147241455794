<template>
  <ApolloQuery
    :query="require('@/graphql/queries/AllCompanies.gql')"
    :variables="{ rerenderKey: rerenderKey, page, perPage, filter: getCompanyFilter, sortField: sort.field, sortOrder: sort.order }"
    @result="doneLoadingCompanies">
    <template slot-scope="{ result: { error, data }, isLoading }">
      <div class="lsg-companys">
        <div class="main__header">
          <div class="main__header__item left">
            <mq-layout :mq="['sm', 'xsm', 'md', 'lg']" class="filter_hide">
              <span class="btn btn_ico" @click="$store.state.data.companies.showFilter = !$store.state.data.companies.showFilter" :class="{'disable': $store.state.data.companies.showFilter}">
                <FilterIco/>
                Скрыть фильтры
              </span>
            </mq-layout>
            <button v-if="['admin'].includes($store.state.me.role)" class="btn" type="submit" @click="$router.push({ name: 'add_base' })">
              <i>
                <PlusIco/>
              </i>
              <span>Загрузить базу</span>
            </button>
          </div>
          <div class="main__header__item">
            <h1>{{ title }}</h1>
          </div>
          <div class="main__header__item right">
            <button v-if="['admin', 'headmanager', 'callcenter', 'manager', 'sop'].includes($store.state.me.role)" class="btn" style="margin-right: 10px" type="submit" @click="$router.push({ name: 'sign' })">
              <i>
                <DocPlus/>
              </i>
              <span>Онлайн подписание</span>
            </button>
            <button class="btn" type="submit" @click="$router.push({ name: 'add_company' })">
              <i>
                <PlusIco/>
              </i>
              <span>Создать компанию</span>
            </button>
          </div>
        </div>
        <div class="main__content">
          <div class="tbl tbl--divided">
            <div class="tbl__header" v-if="$store.state.data.companies.showFilter">
              <div class="tbl__filter">
                <label class="tbl__filter__item" v-if="showFilter('params')">
                  <SelectComponent
                    :options="coneOptions"
                    :placeholder="'Параметры 1С'"
                    :multiple="false"
                    @input="onAtagsChange"
                    :value="atagsSelectValue"
                  />
                </label>
                <label class="tbl__filter__item" v-if="showFilter('tags')">
                  <TagsInput :tags="filter.tags ? $store.state.allTags.filter(s => filter.tags.includes(s.id)).map(item => ({value: item.id, label: item.name})) : []" @input="onTagsChanged"/>
                </label>
                <label class="tbl__filter__item" v-if="showFilter('responsible')">
                  <v-select appendToBody
                            :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))"
                            placeholder="Выберите менеджера"
                            @input="onUserChange"
                            :value="filter.responsible_user_id ? { value: filter.responsible_user_id, label: $store.state.allUsers.find(s => s.id === filter.responsible_user_id).name} : null"
                  >
                    <template slot="no-options">Нет результатов</template>
                    <template #open-indicator="{ attributes }">
                      <div v-bind="attributes">
                        <DownIcon/>
                      </div>
                    </template>
                  </v-select>
                </label>
                <label class="tbl__filter__item" v-if="showFilter('group')">
                  <v-select appendToBody
                            placeholder="Выберите подразделения"
                            :options="$store.state.allGroups.map(item => ({ value: item.id, label: item.name }))"
                            @input="onGroupChange"
                            multiple
                            :value="filter.group_id ? $store.state.allGroups.filter(s => filter.group_id.includes(s.id)).map(item => ({ value: item.id, label: item.name })) : []"
                  >
                    <template slot="no-options">Нет результатов</template>
                    <template #open-indicator="{ attributes }">
                      <div v-bind="attributes">
                        <DownIcon/>
                      </div>
                    </template>
                  </v-select>
                </label>
                <label class="tbl__filter__item" v-if="showFilter('inn')">
                  <input
                    type="text"
                    v-model="filter.inn"
                    @change="onInnChange"
                    placeholder="ИНН"
                  />
                </label>
                <label class="tbl__filter__item" v-if="showFilter('q')">
                  <input
                    type="text"
                    v-model="filter.q"
                    @change="onQChange"
                    placeholder="Поиск"
                  />
                </label>
                <label class="tbl__filter__item" v-if="showFilter('holding')">
                  <v-select appendToBody
                            :options="$store.state.allHoldings.map(item => ({ value: item.id, label: item.name }))"
                            placeholder="Выберите холдинг"
                            @input="onHoldingChange"
                            :value="filter.holding ? { value: filter.holding, label: $store.state.allHoldings.find(s => s.id === filter.holding).name} : null"
                            label="label"
                  >
                    <template slot="no-options">Нет результатов</template>
                    <template #open-indicator="{ attributes }">
                      <div v-bind="attributes">
                        <DownIcon/>
                      </div>
                    </template>
                  </v-select>
                </label>
                <label class="tbl__filter__item" v-if="showFilter('rating')">
                  <SelectComponent
                    :options="ratingOptions"
                    :placeholder="'Рейтинг'"
                    :multiple="false"
                    @input="onRatingChange"
                    :value="filter.rating"
                  />
                </label>
                <label class="tbl__filter__item" v-if="showFilter('types')">
                  <SelectComponent
                    :options="$store.state.data.companies.typeOptions"
                    :placeholder="'Тип'"
                    :multiple="true"
                    @input="onTypesChanged"
                  />
                </label>
                <label class="tbl__filter__item" v-if="showFilter('notTasks')">
                  <div class="checkboxFilter">
                    <SwitcherAlt :value="filter.activeTasks" @updateTable="switchActiveTasks" />
                    <!-- <input type="checkbox" id="checkbox" @click="onActiveTasks" v-model="filter.activeTasks"> -->
                    <label for="checkbox"> Нет задач</label>
                  </div>
                </label>
                <label class="tbl__filter__item" v-if="showFilter('limit')">
                  <div class="checkboxFilter">
                    <SwitcherAlt :value="filter.limit" @updateTable="switchLimit" />
                    <!-- <input type="checkbox" id="limitCheckbox" @click="onLimit" v-model="filter.limit"> -->
                    <label> Есть лимит</label>
                  </div>
                </label>
                <label class="tbl__filter__item" v-if="showFilter('sign')">
                  <div class="checkboxFilter">
                    <SwitcherAlt :value="filter.signed" @updateTable="switchSigned" />
                    <label> Есть согласие</label>
                  </div>
                </label>
              </div>
              <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
                <a href="#" class="tbl__setting" @click.prevent="switchColumnModal">
                  <SettingsIcon/>
                </a>
              </mq-layout>
            </div>
            <div v-if="error" class="messages apollo">
              <div v-if="error" class="messages__status error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
            </div>
            <div v-if="isLoading" class="messages apollo">
              <div v-if="isLoading" class="messages__status">Загрузка...</div>
            </div>
            <div class="tbl__actions" v-if="$store.state.checkedCompanies.length">
              <div class="tbl__actions__main">
                <div class="tbl__actions__count">
                  <span>Выбрано: {{ $store.state.checkedCompanies.length }}</span>
                </div>
                <div class="tbl__actions__items">
                  <div class="tbl__actions__item">
                    <a :class="{'active': actionType === 'switchResponsible'}" @click.prevent="actionType = 'switchResponsible'" href="#">
                      <i aria-hidden="true">
                        <ResponsibleIcon/>
                      </i>
                      <span>Сменить ответственного</span>
                    </a>
                    <div class="table-actions__drop" v-if="actionType === 'switchResponsible'">
                      <div class="table-actions__container">
                        <v-select appendToBody :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" @input="onResponsibleChange" placeholder="Выберите исполнителя" v-click-outside="vcoConfig">
                          <template slot="no-options">Нет результатов</template>
                          <template #open-indicator="{ attributes }">
                            <div v-bind="attributes">
                              <DownIcon/>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                  <div class="tbl__actions__item">
                    <a href="#" type="button" :class="{'active': actionType === 'addcompany'}" @click.prevent="actionType = 'addcompany'">
                      <i aria-hidden="true">
                        <PlusIco/>
                      </i>
                      <span>Добавить задачу</span>
                    </a>
                    <div class="table-actions__drop">
                      <LsgActionsNew type="Company" v-if="actionType === 'addcompany'" v-click-outside="vcoConfigTask" :ids="$store.state.checkedCompanies"/>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="$store.state.me.role === 'admin'" class="tbl__actions__rest">
                <a @click.prevent="showDeleteModal = true" class="red bold" href="#">
                  <span>Удалить</span>
                </a>
              </div>
            </div>
            <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
              <vue-good-table
                :columns="$store.state.data.companies.columns"
                :pagination-options="{
                  enabled: true,
                  perPage: perPage
                }"
                :select-options="{
                  selectOnCheckboxOnly: true,
                  selectionText: 'строка выбрана',
                  clearSelectionText: 'очистить',
                  enabled: true
                }"
                :rows="data ? data.allCompanies : []"
                @on-selected-rows-change="selectedRowsChange"
                @on-sort-change="sortingField2"
                compactMode
              >
                <template slot="table-row" slot-scope="props">
                  <template v-if="isLoading">
                    <div class="sceleton"></div>
                  </template>
                  <template v-else>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'name'">{{ props.row.name }}</router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'types'">
                      <div class="company-types">
                        <span
                          class="tag"
                          :class="`tag--${typeColor[type]}`"
                          v-for="type in props.row.types"
                          :key="type"
                        >
                          {{ type }}
                        </span>
                      </div>
                    </router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'createdAt'">{{ getDate(props.row.createdAt) }}</router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'inn'">{{ props.row.inn }}</router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'email'">{{ props.row.email }}</router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'phone'">{{ getFirstPhone(props.row.phone) }}</router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'group_id'">{{ (props.row.group && props.row.group.name) || 'Нет номера группы' }}</router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'responsible_user_id'">{{ props.row.responsible_user && props.row.responsible_user.name ? props.row.responsible_user.name : 'Нет менеджера' }}</router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'limit_summ'">{{ prettifyPrice(props.row.limit_summ) }}</router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'limit_count'">{{ props.row.limit_count }}</router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'tasks'">{{ checkTasks(props.row.tasks) }}</router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'lastNote'">{{ props.row.lastNote && props.row.lastNote.text }}</router-link>
                    <router-link :to="{ name: 'company', params: { id: props.row._id } }" v-if="props.column.field === 'lastNoteDate'">{{ getDate(props.row.lastNote && props.row.lastNote.createdAt) }}</router-link>
                  </template>
                </template>
                <div slot="emptystate">Данных нет</div>
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="vgt-wrap__footer vgt-clearfix">
                    <div class="footer__row-count vgt-pull-left">
                      <span class="footer__row-count__label">Показано</span>
                      <select @change="perPageChanged(props)" v-model="perPage" class="footer__row-count__select">
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="50">50</option>
                        <option :value="100">100</option>
                        <option :value="200">200</option>
                      </select>
                    </div>
                  </div>
                  <paginate :click-handler="setPage" :page-count="pageCount" :value="page + 1" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
                    <span slot="prevContent">
                      <ArrowLeftIcon/>
                    </span>
                    <span slot="nextContent">
                      <ArrowRightIcon/>
                    </span>
                  </paginate>
                </template>
              </vue-good-table>
            </mq-layout>
            <mq-layout :mq="[ 'xsm', 'md' ]" class="tbl__container">
              <table>
                <thead>
                <tr>
                  <th>
                    <div class="tbl__cols">
                      <span :class="getSortableClass('name')" @click="sortingField('name')">Наименование</span>
                      <span :class="getSortableClass('createdAt')" @click="sortingField('createdAt')">Дата</span>
                      <span :class="getSortableClass('inn')" @click="sortingField('inn')">ИНН</span>
                    </div>
                  </th>
                  <th>
                    <div class="tbl__cols">
                      <span :class="getSortableClass('email')" @click="sortingField('email')">E-mail</span>
                      <span :class="getSortableClass('phone')" @click="sortingField('phone')">Телефон</span>
                    </div>
                  </th>
                  <th>
                    <div class="tbl__cols">
                      <span :class="getSortableClass('group_id')" @click="sortingField('group_id')">Подразделение</span>
                      <span :class="getSortableClass('responsible_user_id')" @click="sortingField('responsible_user_id')">Ответственный</span>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody v-if="isLoading">
                  <tr v-for="(_, index) in Array(10).fill(0)" :key="index">
                    <td v-for="(_, index2) in Array(3).fill(0)" :key="index2">
                      <div class="tbl__content sceleton"></div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="data && data.allCompanies && data.allCompanies.length">
                  <tr :key="index" v-for="(company, index) in data.allCompanies">
                    <td>
                      <div class="tbl__content__container">
                        <checkbox :class-name="$store.state.checkedCompanies.includes(company._id) ? 'active' : ''" v-model="$store.state.checkedCompanies" :value="company._id"></checkbox>
                        <div class="tbl__content">
                          <router-link :to="{name: 'company', params: {id: company._id }}">{{ company.name }}</router-link>
                          <router-link :to="{name: 'company', params: {id: company._id }}">{{ getDate(company.createdAt) }}</router-link>
                          <router-link :to="{name: 'company', params: {id: company._id }}">{{ company.inn }}</router-link>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="tbl__content">
                        <router-link :to="{name: 'company', params: {id: company._id }}">
                          {{ company.email }}
                        </router-link>
                        <router-link :to="{name: 'company', params: {id: company._id }}">
                          {{ getFirstPhone(company.phone) }}
                        </router-link>
                      </div>
                    </td>
                    <td>
                      <div class="tbl__content">
                        <router-link :to="{name: 'company', params: {id: company._id }}">
                          {{ (company.group && company.group.name) || 'Нет номера группы' }}
                        </router-link>
                        <router-link :to="{name: 'company', params: {id: company._id }}">
                          {{ company.responsible_user && company.responsible_user.name ? company.responsible_user.name : 'Нет менеджера' }}
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="5">Нет результатов</td>
                </tr>
                </tbody>
              </table>
            </mq-layout>
            <mq-layout :mq="[ 'xs', 'sm' ]" class="tbl__container tbl__mobile">
              <table>
                <tbody v-if="isLoading">
                  <tr v-for="(_, index) in Array(5).fill(0)" :key="index">
                    <td colspan="1">
                      <div class="tbl__content__container sceleton sceleton--block"></div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="data && data.allCompanies && data.allCompanies.length">
                <tr :key="index" v-for="(company, index) in data.allCompanies">
                  <td>
                    <div class="tbl__content__container">
                      <checkbox :class-name="$store.state.checkedCompanies.includes(company._id) ? 'active' : ''" v-model="$store.state.checkedCompanies" :value="company._id"></checkbox>
                      <div class="tbl__content">
                        <router-link :to="{name: 'company', params: {id: company._id }}">{{ company.name }}</router-link>
                        <router-link :to="{name: 'company', params: {id: company._id }}">{{ getDate(company.createdAt) }}</router-link>
                        <router-link :to="{name: 'company', params: {id: company._id }}">{{ company.inn }}</router-link>
                        <router-link :to="{name: 'company', params: {id: company._id }}">{{ company.email }}</router-link>
                        <router-link :to="{name: 'company', params: {id: company._id }}">{{ getFirstPhone(company.phone) }}</router-link>
                        <router-link :to="{name: 'company', params: {id: company._id }}">{{ (company.group && company.group.name) || 'Нет номера группы' }}</router-link>
                        <router-link :to="{name: 'company', params: {id: company._id }}">{{ company.responsible_user && company.responsible_user.name ? company.responsible_user.name : 'Нет менеджера' }}</router-link>
                        <router-link :to="{name: 'company', params: {id: company._id }}">{{ checkTasks(company.tasks) }}</router-link>
                        <router-link :to="{name: 'company', params: {id: company._id }}">{{ company.lastNote && company.lastNote.text }}</router-link>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="1">Нет результатов</td>
                </tr>
                </tbody>
              </table>
            </mq-layout>
          </div>
          <mq-layout :mq="[ 'xs', 'sm', 'xsm', 'md' ]" class="tbl__footer">
            <div class="tbl__footer__item left"></div>
            <div class="tbl__footer__item">
              <paginate :page-count="pageCount" :value="page + 1" :click-handler="setPage" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
                <span slot="prevContent">
                  <ArrowLeftIcon/>
                </span>
                <span slot="nextContent">
                  <ArrowRightIcon/>
                </span>
              </paginate>
            </div>
            <div class="tbl__footer__item right">
              <label class="tbl__pagination__info">
                Показано
                <span class="bold">{{ perPage }}</span>
                из
                <span class="bold">{{ allCompaniesMeta }}</span>
              </label>
            </div>
          </mq-layout>
          <LsgModal attention v-if="showDeleteModal" @close="showDeleteModal = !showDeleteModal">
            <template slot="ico">
              <AttentionIco/>
            </template>
            <template slot="body">
              <h2>Вы уверены?</h2>
              <p>После удаления данные не могут быть восстановлены</p>
            </template>
            <template slot="footer">
              <button class="button button__red" @click="removeCompanies">Удалить</button>
              <button class="button" @click="showDeleteModal = !showDeleteModal">Отмена</button>
            </template>
          </LsgModal>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import moment from 'moment'
import gql from 'graphql-tag'
import LsgModal from '@/components/ui/LsgModal'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'
// import HoldingInput from '@/components/ui/GraphInputs/HoldingInput'
import AttentionIco from '@/components/svg/attention'
import UPDATE_COMPANIES from '@/graphql/mutations/UpdateCompanies.gql'
import DELETE_COMPANIES from '@/graphql/mutations/DeleteCompanies.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import ArrowLeftIcon from '@/components/svg/arrow_left'
import ArrowRightIcon from '@/components/svg/arrow_right'
import PlusIco from '@/components/svg/plus'
import DownIcon from '@/components/svg/DownIcon'
import ResponsibleIcon from '../../components/svg/ResponsibleIcon'
import LsgActionsNew from '../../components/ui/LsgActionsNew'
import FilterIco from '@/components/svg/new/Filter'
import SettingsIcon from '@/components/svg/settings'
import SwitcherAlt from '@/components/ui/SwitcherAlt'
import DocPlus from '@/components/svg/doc_plus'
import SelectComponent from '@/components/ui/GraphInputs/SelectInput'

const _graphQlHelper = new GraphQLHelper()

export default {
  components: {
    SwitcherAlt,
    LsgActionsNew,
    ResponsibleIcon,
    DownIcon,
    PlusIco,
    ArrowRightIcon,
    ArrowLeftIcon,
    TagsInput,
    LsgModal,
    AttentionIco,
    SelectComponent,
    FilterIco,
    SettingsIcon,
    DocPlus
    // HoldingInput
  },
  data () {
    return {
      showDeleteModal: false,
      title: 'Компании',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Компании'
        }
      ],
      vcoConfig: {
        handler: () => {
          this.actionType = null
        },
        middleware: (event) => {
          let name = event.target.getAttribute('class') || ''
          return name !== null ? !name.includes('mx-') : true
        },
        events: ['dblclick', 'click'],
        isActive: true
      },
      vcoConfigTask: {
        handler: () => {
          this.actionType = null
        },
        middleware: (event) => {
          return this.hasSomeParentTheClass(event.target, '.mx-calendar')
        },
        events: ['dblclick', 'click'],
        isActive: true
      },
      actionType: null,
      page: 0,
      perPage: 50,
      sort: {
        field: 'createdAt',
        order: 'desc'
      },
      filter: {
        q: '',
        name: '',
        email: '',
        phone: '',
        inn: '',
        tags: [],
        responsible_user_id: null,
        group_id: [],
        atags: [],
        activeTasks: false,
        limit: false,
        signed: false,
        rating: null,
        holding: null
      },
      allCompaniesMeta: 0,
      coneOptions: [
        { value: 'light', label: 'ЛА' },
        { value: 'special', label: 'СпецТехника' },
        { value: 'equipment', label: 'Оборудование' },
        { value: 'dept', label: 'Дебитор 60+' },
        { value: 'dept30', label: 'Дебитор 30+' },
        { value: 'dept10', label: 'Дебитор 10+' },
        { value: 'delivered', label: 'Был выдан' },
        { value: 'approved', label: 'Был одобрен' },
        { value: 'workSB', label: 'В работе СБ' },
        { value: 'workUO', label: 'В работе ЮО' },
        { value: 'facePunch', label: 'Изъятие' },
        { value: 'otkazSB', label: 'Отказ СБ' }
      ],
      ratingOptions: [
        { value: 'good', label: 'Хороший' },
        { value: 'average', label: 'Средний' },
        { value: 'bad', label: 'Плохой' },
        { value: 'negative', label: 'Негативный' }
      ],
      typeOptions: [
        { value: 'Лизингополучатель', label: 'Лизингополучатель' },
        { value: 'Поставщик', label: 'Поставщик' },
        { value: 'Поручитель', label: 'Поручитель' },
        { value: 'Агент', label: 'Агент' }
      ],
      typeColor: {
        'Лизингополучатель': 'blue',
        'Поставщик': 'green',
        'Поручитель': 'orange',
        'Агент': 'yellow'
      }
    }
  },
  computed: {
    atagsSelectValue () {
      if (Array.isArray(this.filter.atags)) {
        return this.filter.atags
      } else {
        if (this.filter.atags && this.filter.atags.length) {
          return this.filter.atags[0]
        } else {
          return []
        }
      }
    },
    rerenderKey () {
      return this.$store.state.data.companies.rerenderKey
    },
    pageCount () {
      return Math.ceil(this.allCompaniesMeta / this.perPage)
    },
    getCompanyFilter () {
      let currentFilter = {}
      if (this.filter.name) currentFilter.name = this.filter.name
      if (this.filter.q && this.filter.q.length > 2) currentFilter.q = this.filter.q
      if (this.filter.email) currentFilter.email = this.filter.email
      if (this.filter.phone) currentFilter.phone = this.filter.phone
      if (this.filter.inn) currentFilter.inn = this.filter.inn
      if (this.filter.tags.length) currentFilter.tags = this.filter.tags
      if (this.filter.responsible_user_id) currentFilter.responsible_user_id = this.filter.responsible_user_id
      if (this.filter.group_id.length) currentFilter.group_id = this.filter.group_id
      if (this.filter.atags.length) {
        this.filter.atags.forEach(el => {
          currentFilter[el.value] = true
        })
      }
      if (this.filter.activeTasks) currentFilter.activeTasks = this.filter.activeTasks
      if (this.filter.limit) currentFilter.limit = this.filter.limit
      if (this.filter.signed) currentFilter.signed = this.filter.signed
      if (this.filter.rating) currentFilter.rating = this.filter.rating.value
      if (this.filter.holding) currentFilter.holding_id = this.filter.holding
      if (this.filter.types && this.filter.types.length) currentFilter.types = this.filter.types
      return currentFilter
    }
  },
  apollo: {
    allCompaniesMeta () {
      return {
        query: gql`query($filter: CompanyFilter) {
          _allCompaniesMeta(filter: $filter) {
            count
          }
        }`,
        variables () {
          return {
            filter: this.getCompanyFilter
          }
        },
        update: (data) => {
          this.$nextTick(() => {
            this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
          })
          return data._allCompaniesMeta.count || 0
        }
      }
    }
  },
  created () {
    this.page = this.$store.state.data.companies.page
    this.perPage = this.$store.state.data.companies.perPage
    this.sort = this.$store.state.data.companies.sort
    this.filter = this.$store.state.data.companies.filter

    this.$store.dispatch('allHoldings', this)
  },
  beforeRouteLeave (to, from, next) {
    this.$store.state.data.companies.page = this.page
    this.$store.state.data.companies.perPage = this.perPage
    this.$store.state.data.companies.sort = this.sort
    this.$store.state.data.companies.filter = this.filter
    next()
  },
  methods: {
    showFilter (filterField) {
      const findedFilter = this.$store.state.data.companies.filters.find(filter => (filterField === filter.field))

      if (!findedFilter) return false

      return !findedFilter.hidden
    },
    onHoldingChange (select) {
      if (select) {
        this.filter.holding = select.value
      } else {
        this.filter.holding = null
      }
      this.setPage(1)
    },
    switchActiveTasks (isCheck) {
      this.filter.activeTasks = isCheck
    },
    switchSigned (isCheck) {
      this.filter.signed = isCheck
    },
    switchLimit (isCheck) {
      this.filter.limit = isCheck
    },
    hasSomeParentTheClass (element, classname) {
      if (element.className && element.className.split(' ').indexOf(classname) >= 0) return true
      return element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname)
    },
    selectedRowsChange ({ selectedRows }) {
      this.$store.state.checkedCompanies = selectedRows.map(row => row._id)
    },
    perPageChanged (props) {
      props.perPageChanged({ currentPerPage: this.perPage })
    },
    removeCompanies () {
      this.$apollo.mutate({
        mutation: DELETE_COMPANIES,
        variables: {
          ids: this.$store.state.checkedCompanies
        }
      }).then(() => {
        this.$router.go()
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
          this.$notify({
            group: 'lsg-notify-support',
            title: 'Сообщить в поддержку',
            text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
            type: 'info',
            closeOnClick: true,
            data: {
              info: {
                project: 'Личный кабинет Leasing-trade',
                name: this.$store.state.me.name,
                email: this.$store.state.me.email,
                group_id: this.$store.state.me.group.id,
                role: this.$store.state.me.role,
                localTime: new Date(),
                system: navigator.userAgent,
                url: window.location.href,
                window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                error: item,
                comment: 'Ошибка при удалении компании в списке компаний'
              },
              style: 'cursor: pointer'
            }
          })
        })
      })
    },
    onResponsibleChange (user) {
      if (user) {
        this.$apollo.mutate({
          mutation: UPDATE_COMPANIES,
          variables: {
            ids: this.$store.state.checkedCompanies,
            responsible_user_id: user.value
          }
        }).then(() => {
          this.$router.go()
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
            this.$notify({
              group: 'lsg-notify-support',
              title: 'Сообщить в поддержку',
              text: `Нажав на это сообщение, вы отправите отчёт об ошибке в тех.поддержку`,
              type: 'info',
              closeOnClick: true,
              data: {
                info: {
                  project: 'Личный кабинет Leasing-trade',
                  name: this.$store.state.me.name,
                  email: this.$store.state.me.email,
                  group_id: this.$store.state.me.group.id,
                  role: this.$store.state.me.role,
                  localTime: new Date(),
                  system: navigator.userAgent,
                  url: window.location.href,
                  window: `${window.window.screen.availWidth}px ${window.window.screen.availHeight}px`,
                  error: item,
                  comment: 'Ошибка при создании компании'
                },
                style: 'cursor: pointer'
              }
            })
          })
        })
      }
    },
    setPage (page) {
      this.page = page - 1
    },
    onActiveTasks () {
      this.filter.activeTasks = !this.filter.activeTasks
      this.setPage(1)
    },
    onSigned () {
      this.filter.signed = !this.filter.signed
      this.setPage(1)
    },
    onLimit () {
      this.filter.limit = !this.filter.limit
      this.setPage(1)
    },
    onGroupChange (e) {
      this.filter.group_id = e.map(item => item.value)
      this.setPage(1)
    },
    onAtagsChange (e) {
      if (Array.isArray(e)) this.filter.atags = e
      else {
        if (e) {
          this.filter.atags = [e]
        } else {
          this.filter.atags = []
        }
      }
      this.setPage(1)
    },
    onInnChange (e) {
      this.setPage(1)
    },
    onQChange (e) {
      this.setPage(1)
    },
    onUserChange (select) {
      if (select) {
        this.filter.responsible_user_id = select.value
      } else {
        this.filter.responsible_user_id = null
      }
      this.setPage(1)
    },
    onRatingChange (e) {
      this.filter.rating = e
      this.setPage(1)
    },
    onTagsChanged (tags) {
      this.filter.tags = tags.map(item => item.value)
      this.setPage(1)
    },
    onTypesChanged (types) {
      this.filter.types = types
      this.setPage(1)
    },
    goToPrevPage (e) {
      e.preventDefault()
      if (this.page !== 0) {
        this.page--
      }
    },
    doneLoadingCompanies ({ data }) {
      this.$store.commit('allCompanies', { data, isMore: false })
    },
    goToNextPage (e, data) {
      e.preventDefault()
      const dataLength = data && data.allCompanies && data.allCompanies.length ? data.allCompanies.length : 0
      if ((dataLength < this.perPage) || (this.allCompaniesMeta === (this.page + 1) * this.perPage)) return
      this.page++
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    isDisabled (data) {
      const dataLength = data && data.allCompanies && data.allCompanies.length ? data.allCompanies.length : 0
      return (dataLength < this.perPage) || (this.allCompaniesMeta === (this.page + 1) * this.perPage)
    },
    sortingField2 (params) {
      if (params[0].type === 'none') {
        this.sort = {
          field: 'createdAt',
          order: 'desc'
        }
        return
      }
      this.sort.order = params[0].type
      this.sort.field = params[0].field
    },
    sortingField (columnName) {
      if (columnName === this.sort.field) this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc'
      this.sort.field = columnName
    },
    getSortableClass (columnName) {
      let currentClass = 'lsg-table-sort'
      if (columnName === this.sort.field) currentClass += ' sort-' + this.sort.order

      return currentClass
    },
    getFirstPhone (phones) {
      if (phones && phones.length) return phones[0]

      return 'Нет номера телефона'
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString('ru', {
        maximumFractionDigits: 2
      })
    },
    checkTasks (tasks) {
      if (tasks.length) {
        let count = 0
        tasks.forEach(tsk => tsk.is_completed ? count : count++)
        return count ? `Есть активные ( ${count} )` : 'Нет активных'
      } else return 'Нет активных'
    },
    switchColumnModal () {
      this.$store.state.data.companies.columnModal = !this.$store.state.data.companies.columnModal
    }
  }
}
</script>

<style lang="stylus" scoped>

  .company-types {
    display flex
    flex-direction column

    > *:not(:last-child) {
      margin-bottom 5px
    }
  }

  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  .sceleton
    position: relative
    display: inline-block
    height: 40px
    width: 100px
    margin: 0 10.5px 5px
    border-radius: 10px
    background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
    background-size: 400%
    animation: loading 1.2s ease-in-out infinite;

    &--block
      width: 100%
      height: 250px

  .checkboxFilter {
    display flex
    flex-direction row
    align-items center
  }
  .switcher {
    margin-bottom -5px
    margin-right 10px
  }
</style>
